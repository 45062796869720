import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import { fetchHandshake } from "@/services/handshake";
import { getHomeJson } from "@/services/home";
import { renderHomeSection } from "@/utils/home";
import Seo from "@/components/seo";

const HomePage = ({ home }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("home");

  console.log("home", home);

  return (
    <>
      <Seo />
      {home?.length
        ? home.map((section) =>
            section?.active
              ? renderHomeSection(section.key, section, t, language)
              : null
          )
        : null}
    </>
  );
};

export default HomePage;

export async function getServerSideProps({ locale, req, res }) {
  const ua = req.headers["user-agent"] || "";
  const isMobile =
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/i.test(
      ua
    );
  console.log("isMobile", isMobile);
  // get handshake from cookies or generate new handshake
  const handshake = await fetchHandshake({ req, res, locale });

  // get home page structure json
  const { data, error } = await getHomeJson(handshake, isMobile);
  if (error) {
    return {
      props: {},
    };
  }
  // path of home json data direct
  const homeJson = data?.data ? data?.data?.at(0) : [];

  const topLinks =
    homeJson?.find((item) => item.key === "top-links" && item.active) || {};
  const topLinksInstallment =
    homeJson?.find(
      (item) => item.key === "top-links-installment" && item.active
    ) || {};

  const firstSection =
    topLinks && topLinksInstallment
      ? {
          position: 1,
          key: "first-section",
          active: true,
          top_links: topLinks,
          top_links_installment: topLinksInstallment,
        }
      : {};

  const static_parts = [...homeJson, firstSection]?.filter(
    (item) =>
      item?.active &&
      item?.key !== "top-links" &&
      item?.key !== "top-links-installment"
  );

  const home = (Array.isArray(static_parts) ? static_parts : []).sort(
    (a, b) => +a.position - +b.position
  );

  return {
    props: {
      home,
      ...(await serverSideTranslations(locale, ["common", "home", "product"])),
    },
  };
}
