import LazyLoading from "@/components/LazyLoading";
import MainBanner from "@/components/global/swiper/MainBanner"; // eager
import Category from "@/components/home/Category"; // eager
import CategoryCircle from "@/components/home/CategoryCircle";
import StaticBadges from "@/components/home/StaticBadges"; // eager
import StaticPages from "@/components/home/StaticPages"; // eager
import { getProductsByCategory } from "@/services/category";
import dynamic from "next/dynamic";

const BannersLayout = dynamic(
  () => import("../../components/home/banners/BannersLayout"),
  {
    loading: () => (
      <LazyLoading parentClassName={"min-h-[300px] md:min-h-[450px]"} />
    ),
  }
);
const Products = dynamic(() => import("../../components/home/Products"), {
  loading: () => (
    <LazyLoading parentClassName={"min-h-[400px] sm:min-h-[512px]"} />
  ),
});
const Brands = dynamic(() => import("../../components/home/Brands"), {
  loading: () => <LazyLoading parentClassName={"min-h-[200px]"} />,
});
const BannersGrid = dynamic(() => import("../../components/home/BannersGrid"), {
  loading: () => <LazyLoading parentClassName={"min-h-[200px]"} />,
});
const SwiperOffers = dynamic(
  () => import("../../components/home/SwiperOffers"),
  {
    loading: () => <LazyLoading parentClassName={"min-h-[200px]"} />,
  }
);
const CategoryProducts = dynamic(
  () => import("../../components/home/CategoryProducts"),
  {
    loading: () => <LazyLoading parentClassName={"min-h-[600px]"} />,
  }
);

export const getTabsDataV2 = async (handshake, tabs) => {
  if ("value" in tabs) {
    const tabsOneApis = tabs?.value?.map((tab) =>
      tab?.status ? getProductsByCategory(handshake, tab?.id, 15, 0, "") : null
    );
    const tabsData = await Promise.all(tabsOneApis);
    const tabsProducts = tabs.value
      .map((tab, idx) => ({
        ...tab,
        products: (tabsData[idx]?.data?.data?.products || []).filter(
          (product) => product?.stock?.qty > 0 && product?.stock?.is_in_stock
        ),
      }))
      .filter((tab) => tab.products?.length);
    return { ...tabs, value: tabsProducts };
  }
  return tabs;
};

export const renderHomeSection = (sectionType, data, t, language) => {
  switch (sectionType) {
    case "banners-5-5":
    case "banners-1-5":
    case "banners-1-1":
    case "banners-1-3":
    case "banners-1-2":
    case "banners-6-1":
    case "banners-1-2-2":
    case "banners-2-2-1":
    case "banners-1-1-1":
    case "banners-2-1-2-1":
      return <BannersLayout {...{ data, locale: language }} />;
    case "categories":
      return <Category {...{ data, language, t }} />; // eager
    case "categories-circle":
      return <CategoryCircle {...{ data, language, t }} />; // eager
    case "main-banners":
      return <MainBanner {...{ data, language }} />; // eager
    case "product-list":
      return <Products {...{ data, language, t }} />;
    case "brands":
      return <Brands {...{ data, language, t }} />;
    case "offers":
    case "two-main-banners":
      return <BannersGrid {...{ data, language }} />;
    case "swapper-offers":
      return <SwiperOffers {...{ data, language }} />;
    case "first-section":
      return <StaticPages {...{ data, language }} />; // eager
    case "top-offers":
      return <StaticBadges {...{ data, language }} />; // eager
    case "best-selling":
      return <CategoryProducts {...{ data }} />;
    default:
      return null;
  }
};
